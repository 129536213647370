import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["duprForm", "inputField", "submitButton"];

    showForm() {
        this.duprFormTarget.classList.remove("hidden");
        this.submitButtonTarget.setAttribute("disabled", "true");
    }

    hideForm() {
        this.duprFormTarget.classList.add("hidden");
        
        this.submitButtonTarget.removeAttribute("disabled");

        this.duprFormTarget.querySelectorAll("input").forEach(input => {
            input.value = "";
        });
    }

    validateInput() {
        if (this.inputFieldTarget.value.trim() !== "") {
            this.submitButtonTarget.removeAttribute("disabled");
        } else {
            this.submitButtonTarget.setAttribute("disabled", "true");
        }
    }
}
