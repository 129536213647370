import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["logo"]
    static values = {
        interval: { type: Number, default: 15000 }, // Time between rotations in ms
        fadeDuration: { type: Number, default: 2000 }, // Duration of fade effect in ms (increased to 20 seconds)
        visibleCount: { type: Number, default: 3 } // Number of logos to show at once
    }

    connect() {
        console.log("Logo rotation controller connected")
        console.log("Found logos:", this.logoTargets.length)

        this.totalLogos = 10 // Number of different logos we have
        this.positions = 3 // Number of fixed positions

        // Group logos by position for easier access
        this.logosByPosition = this.groupLogosByPosition()

        // Initialize with random, non-duplicate indices
        this.currentIndices = this.getRandomIndices()

        // Show initial logos
        this.showInitialLogos()

        // Start independent rotations for each position
        this.startIndependentRotations()
    }

    groupLogosByPosition() {
        return this.logoTargets.reduce((acc, logo) => {
            const position = parseInt(logo.dataset.position)
            if (!acc[position]) acc[position] = []
            acc[position].push(logo)
            return acc
        }, {})
    }

    getRandomIndices() {
        // Create array of all possible indices
        const indices = Array.from({ length: this.totalLogos }, (_, i) => i)

        // Shuffle array
        for (let i = indices.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [indices[i], indices[j]] = [indices[j], indices[i]]
        }

        // Take first 3 indices for initial positions
        return indices.slice(0, this.positions)
    }

    getNextRandomIndex(currentIndices) {
        const availableIndices = Array.from({ length: this.totalLogos }, (_, i) => i)
            .filter(i => !currentIndices.includes(i))

        return availableIndices[Math.floor(Math.random() * availableIndices.length)]
    }

    showInitialLogos() {
        for (let position = 0; position < this.positions; position++) {
            const logos = this.logosByPosition[position] || []
            logos.forEach(logo => {
                const index = parseInt(logo.dataset.index)
                if (index === this.currentIndices[position]) {
                    logo.classList.remove('hidden')
                    logo.style.opacity = '1'
                } else {
                    logo.classList.add('hidden')
                    logo.style.opacity = '0'
                }
            })
        }
    }

    startIndependentRotations() {
        // Create separate intervals for each position
        this.rotationIntervals = []

        for (let position = 0; position < this.positions; position++) {
            this.scheduleNextRotation(position)
        }
    }

    scheduleNextRotation(position) {
        // Random interval between 5 and 15 seconds
        const randomInterval = Math.random() * (15000 - 5000) + 5000

        const interval = setTimeout(() => {
            this.rotatePosition(position)
            // Schedule next rotation after this one completes
            this.scheduleNextRotation(position)
        }, randomInterval)

        this.rotationIntervals[position] = interval
    }

    rotatePosition(position) {
        const logos = this.logosByPosition[position] || []
        const currentIndex = this.currentIndices[position]

        // Get next random index that's not currently shown in any position
        const nextIndex = this.getNextRandomIndex(this.currentIndices)

        // Find current and next logos
        const currentLogo = logos.find(logo => parseInt(logo.dataset.index) === currentIndex)
        const nextLogo = logos.find(logo => parseInt(logo.dataset.index) === nextIndex)

        if (currentLogo && nextLogo) {
            this.transitionLogos(currentLogo, nextLogo)
        }

        // Update the current indices
        this.currentIndices[position] = nextIndex
    }

    transitionLogos(currentLogo, nextLogo) {
        // Prepare next logo
        nextLogo.classList.remove('hidden')
        nextLogo.style.opacity = '0'

        // Trigger reflow to ensure transitions work
        void nextLogo.offsetWidth

        // Fade out current logo and fade in next logo
        currentLogo.style.opacity = '0'
        nextLogo.style.opacity = '1'

        // Hide current logo after transition
        setTimeout(() => {
            currentLogo.classList.add('hidden')
        }, this.fadeDurationValue)
    }

    disconnect() {
        // Clear all rotation intervals
        if (this.rotationIntervals) {
            this.rotationIntervals.forEach(interval => {
                clearTimeout(interval)
            })
        }
    }
} 