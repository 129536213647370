import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import JSConfetti from "js-confetti";

export default class extends Controller {
  static targets = [
    "team1",
    "team2",
    "slot",
    "button",
    "autoDrawButton",
    "popup",
    "success",
    "error",
    "teams",
  ];
  static values = {
    teams: Array,
    matchId: String,
    tournamentId: String,
    // [{"team1": "657bfeab-8ec2-49aa-b115-65e0bc033b59", "team2": "3ff22245-9df1-4932-8819-dfc76103b131", "matchId": "04f4e7a2-c0bd-4ac7-8e89-650143e13039"}, {"team1": "39d93e98-97bd-42c6-b86c-3eba3f834141", "team2": "09f215b5-bb9d-4285-8e1f-3a45f86539cf", "matchId": "546783f3-1ead-4966-848a-1d3c62a02980"}]
    definedMatches: Array,
    format: String,
    tournamentGroupTeamId: String,
    tournamentGroupId: String,
    matchType: String,
  };
  static alreadyDrawn = [];

  connect() {
    this.jsConfetti = new JSConfetti();
    this.prepareTeams();
    this.alreadyDrawn = [];

    if (this.hasDefinedMatchesValue) {
      this.validateDefinedMatches();
    }
  }

  prepareTeams() {
    this.teamCards = this.teamsValue
      .map((teamId) => {
        const teamElement = document.querySelector(
          `[data-team-id="${teamId}"]`,
        );
        return teamElement
          ? { id: teamId, element: teamElement.cloneNode(true) }
          : null;
      })
      .filter((team) => team !== null);

    this.shuffleTeamCards();
    this.assignRandomTeamsToSlots();
  }

  validateDefinedMatches() {
    // console.log('definedMatches:', this?.definedMatchesValue)

    this.definedMatchesValue =
      this?.definedMatchesValue?.filter((value) => {
        return (
          value.team1 &&
          value.team2 &&
          value.team1 != value.team2 &&
          this.teamsValue.includes(value.team1) &&
          this.teamsValue.includes(value.team2)
        );
      }) ?? [];

    // console.log('validated definedMatches: ', this?.definedMatchesValue, this.teamsValue)
  }

  shuffleTeamCards() {
    for (let i = this.teamCards.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.teamCards[i], this.teamCards[j]] = [
        this.teamCards[j],
        this.teamCards[i],
      ];
    }
  }

  assignRandomTeamsToSlots() {
    this.slotTargets.forEach((slot, index) => {
      if (
        !slot.classList.contains("team1") &&
        !slot.classList.contains("team2")
      ) {
        slot.innerHTML = this.teamCards[index]?.element.outerHTML || "";
      }
    });
  }

  start() {
    this.disableButton();
    this.disableAutoDrawButton(true);
    this.selectedTeamIds = [];
    this.rotateThroughAllSlots();
  }

  startAutoDraw() {
    if (document.getElementById("not-drawn") != null) {
      this.disableButton(true);
      this.disableAutoDrawButton();
      this.selectedTeamIds = [];
      this.rotateThroughAllSlots(10, true);
    } else {
      clearInterval(this.autoDrawInterval);

      this.jsConfetti.addConfetti({
        confettiNumber: 200,
      });
    }
  }

  initializeAutoDraw() {
    if (document.getElementById("not-drawn") != null) {
      let attempts = 0;
      console.log("initializeAutoDraw: ", document.getElementById("not-drawn"));
      if (document.getElementById("not-drawn") != null) {
        if (attempts < 50) {
          this.startAutoDraw();
          attempts++;
          this.autoDrawInterval = setInterval(() => {
            this.startAutoDraw();
            attempts++;
          }, 3000);
        }
      }
    }
  }

  stopAutoDraw() {
    clearInterval(this.autoDrawInterval);
    this.buttonTarget.dataset.action = "click->draw#continueDraw";
    const startText = this.buttonTarget.dataset.start;
    this.resetButton(
      `<i class="fa-solid fa-play mr-2"></i> ${startText}`,
      "btn-primary",
    );
    if (this.hasAutoDrawButtonTarget) {
      this.autoDrawButtonTarget.dataset.action =
        "click->draw#initializeAutoDraw";
      this.resetAutoDrawButton(
        `<i class="fa-solid fa-forward-fast mr-2"></i> ${startText}`,
        "btn-tertiary",
      );
    }
  }

  rotateThroughAllSlots(rotations = 30, auto = false) {
    let selection;
    const interval = setInterval(() => {
      this.slotTargets.forEach((slot) => {
        selection = this.randomizeSelection();
        const randomTeam = selection.team;
        slot.innerHTML = randomTeam.element.outerHTML;
      });

      if (--rotations <= 0) {
        clearInterval(interval);
        if (!auto) {
          this.selectFinalTeams();
        } else {
          this.selectFinalTeamsAuto();
        }
      }
    }, 100);
  }

  randomizeSelection() {
    const exclusions = [];
    exclusions.push(
      ...this?.definedMatchesValue.flatMap((match) => [
        match.team1,
        match.team2,
      ]),
      ...this.alreadyDrawn
    );

    let randomIndex;

    do {
      randomIndex = Math.floor(Math.random() * this.teamCards.length);
    } while (
      exclusions.includes(randomIndex)
    );

    return { index: randomIndex, team: { ...this.teamCards[randomIndex] } };
  }

  async selectFinalTeams() {
    if (["round_robin", "group"].includes(this.formatValue)) {
      await this.selectFinalTeamsForRoundRobin();
    } else {
      await this.selectFinalTeamsForKnockOut();
    }
  }

  async selectFinalTeamsAuto() {
    console.log('duy alreadyDrawn', this.alreadyDrawn)
    console.log("selectFinalTeamsAuti: ", this.definedMatchesValue);
    if (["round_robin", "group"].includes(this.formatValue)) {
      await this.selectFinalTeamsForRoundRobin(true);
    } else {
      await this.selectFinalTeamsForKnockOut(true);
    }
  }

  async selectFinalTeamsForRoundRobin(auto = false) {
    console.log("selectFinalTeamsForRoundRobin: ", this.definedMatchesValue);

    let randomTeam;
    let selection;

    if (!randomTeam) {
      selection = this.randomizeSelection();
      this.alreadyDrawn.push(selection.index)
      randomTeam = selection.team;
    }

    this.team1Target.innerHTML = randomTeam.element.outerHTML;

    this.selectedTeamIds = [randomTeam.id];

    if (!auto) {
      await this.updateMatchTeamsForTournamentGroupTeam();
    } else {
      await this.updateMatchTeamsForTournamentGroupTeamAuto();
    }
  }

  async selectFinalTeamsForKnockOut() {
    console.log("selectFinalTeamsForKnockOut: ", this.definedMatchesValue);

    let team1RandomTeam;
    let team2RandomTeam;

    if (
      this?.definedMatchesValue
        ?.map((match) => match.matchId)
        .includes(this?.matchIdValue)
    ) {
      const selectedDefinedMatch = this?.definedMatchesValue?.find(
        (match) => match.matchId == this?.matchIdValue,
      );

      team1RandomTeam = this.teamCards.find(
        (card) => card.id == selectedDefinedMatch.team1,
      );
      team2RandomTeam = this.teamCards.find(
        (card) => card.id == selectedDefinedMatch.team2,
      );
    }

    let selection1;
    let selection2;

    if (!team1RandomTeam || !team2RandomTeam) {
      selection1 = this.randomizeSelection();
      this.alreadyDrawn.push(selection1.index)
      team1RandomTeam = selection1.team;
      selection2 = this.randomizeSelection();
      team2RandomTeam = selection2.team;
    }

    if (team1RandomTeam.id === team2RandomTeam.id){
      this.alreadyDrawn.push(selection2.index)
    }

    while (team1RandomTeam.id === team2RandomTeam.id) {
      this.alreadyDrawn.remove(selection2.index)
      selection2 = this.randomizeSelection();
      this.alreadyDrawn.push(selection2.index)
      team2RandomTeam = selection2.team;
    }

    this.team1Target.innerHTML = team1RandomTeam.element.outerHTML;
    this.team2Target.innerHTML = team2RandomTeam.element.outerHTML;

    this.selectedTeamIds = [team1RandomTeam.id, team2RandomTeam.id];
    await this.updateMatchTeamsForKnockOut();
  }

  async updateMatchTeamsForTournamentGroupTeam() {
    console.log("updateMatchTeams: ", this.selectedTeamIds, this);

    const body = {
      tournament_group_team_id: this.tournamentGroupTeamIdValue,
      team_id: this.selectedTeamIds[0],
    };

    try {
      const response = await post(
        `/tournaments/${this.tournamentIdValue}/organizer/draws/set_teams_to_tournament_group_team`,
        {
          body: JSON.stringify(body),
          responseKind: "json",
        },
      );

      if (!response.ok) throw new Error("Network response was not ok.");

      this.handleSuccess();
    } catch (error) {
      console.error("Error:", error);
      this.handleError();
    }
  }

  async updateMatchTeamsForTournamentGroupTeamAuto() {
    console.log("updateMatchTeams: ", this.selectedTeamIds, this);

    const body = {
      tournament_group_id: this.tournamentGroupIdValue,
      team_id: this.selectedTeamIds[0],
    };

    try {
      const response = await post(
        `/tournaments/${this.tournamentIdValue}/organizer/draws/set_teams_to_tournament_group`,
        {
          body: JSON.stringify(body),
          responseKind: "json",
        },
      );

      if (!response.ok) throw new Error("Network response was not ok.");

      this.handleSuccessAutoDraw();
    } catch (error) {
      console.error("Error:", error);
      this.handleError();
    }
  }

  async updateMatchTeamsForKnockOut() {
    console.log("updateMatchTeams: ", this.selectedTeamIds);

    const body = {
      match_id: this.matchIdValue,
      team1_id: this.selectedTeamIds[0],
      team2_id: this.selectedTeamIds[1],
    };

    try {
      const response = await post(
        `/tournaments/${this.tournamentIdValue}/organizer/draws/${this.matchIdValue}/set_teams`,
        {
          body: JSON.stringify(body),
          responseKind: "json",
        },
      );

      if (!response.ok) throw new Error("Network response was not ok.");

      this.handleSuccess();
    } catch (error) {
      console.error("Error:", error);
      this.handleError();
    }
  }
  
  disableButton(disableOnly = false) {
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.add("opacity-50");
    if (!disableOnly) {
      const textDrawing =  this.buttonTarget.dataset.drawing;
      this.buttonTarget.innerHTML = `<i class="fa-solid fa-tennis-ball fa-spin"></i> ${textDrawing}`;
    }
  }
  disableAutoDrawButton(disableOnly = false) {
    if (this.hasAutoDrawButtonTarget) {
      const textDrawing =  this.autoDrawButtonTarget.dataset.drawing;
      this.autoDrawButtonTarget.disabled = true;
      this.autoDrawButtonTarget.classList.remove("btn-danger");
      this.autoDrawButtonTarget.classList.add("btn-tertiary");
      if (!disableOnly) {
        this.autoDrawButtonTarget.innerHTML = `<i class="fa-solid fa-tennis-ball fa-spin"></i> ${textDrawing}`;
      }
    }
  }

  handleSuccess() {
    this.jsConfetti.addConfetti();
    if (this.successTarget) {
      this.successTarget.classList.remove("hidden");
    }

    const buttonText = ("round_robin", "group").includes(this.formatValue)
      ? this.buttonTarget.dataset.nextTeam
      : this.buttonTarget.dataset.nextMatch;
    this.resetButton(buttonText, "btn-blue");

    if (this.buttonTarget) {
      this.buttonTarget.dataset.action = "click->draw#continueDraw";
    }

    if (this.hasAutoDrawButtonTarget) {
      this.autoDrawButtonTarget.dataset.action = "click->draw#continueDraw";
    }
  }

  handleSuccessAutoDraw() {
    this.jsConfetti.addConfetti({ confettiNumber: 30 });

    const notDrawnElement = document.getElementById("not-drawn");
    if (notDrawnElement != null) {
      notDrawnElement.classList.add("bg-green-500", "text-white");
      const team1Avatar = this.team1Target.querySelector(
        "#team-player-1-avatar",
      ).src;
      const team1FullName = this.team1Target.querySelector(
        "#team-player-1-full-name",
      ).textContent;

      if (this.matchTypeValue == "doubles") {
        const team2FullName = this.team1Target.querySelector(
          "#team-player-2-full-name",
        ).textContent;
        const team2Avatar = this.team1Target.querySelector(
          "#team-player-2-avatar",
        ).src;
        notDrawnElement.insertAdjacentHTML(
          "afterend",
          `
          <div id="team-drawn" class="flex h-20 p-3 bg-white rounded-xl text-black items-center">
            <div class="shrink-0 flex -space-x-4 rtl:space-x-reverse">
              <a class="relative group clickable-ignore">
                <img class="group-hover:shadow-lg group-hover:border-blue-300 h-10 w-10 rounded-full border-2 border-white object-cover" alt="" src="${team1Avatar}">
              </a>                      
              <a class="relative group clickable-ignore">
                <img class="group-hover:shadow-lg group-hover:border-blue-300 h-10 w-10 rounded-full border-2 border-white object-cover" alt="" src="${team2Avatar}">
              </a>                  
            </div>
            <div class="pl-2">
              <a class="flex items-center gap-2 text-md text-inherit">
                <p class="font-medium line-clamp-1">${team1FullName}</p>
              </a>                      
              <a class="flex items-center gap-2 text-md text-inherit">
                <p class="font-medium line-clamp-1">${team2FullName}</p>
              </a>
            </div>
          </div>
          `,
        );
      } else {
        notDrawnElement.insertAdjacentHTML(
          "afterend",
          `
            <div id="team-drawn" class="flex h-20 p-3 bg-white rounded-xl text-black items-center">
              <div class="shrink-0 flex -space-x-4 rtl:space-x-reverse">
                <a class="relative group clickable-ignore">
                  <img class="group-hover:shadow-lg group-hover:border-blue-300 h-10 w-10 rounded-full border-2 border-white object-cover" alt="Player avatar" src="${team1Avatar}">
                </a>
              </div>
              <div class="pl-2">
                <a class="flex items-center gap-2 text-md text-inherit">
                  <p class="font-medium line-clamp-1">${team1FullName}</p>
                </a>
              </div>
            </div>
            `,
        );
      }
      notDrawnElement.remove();
      notDrawnElement.classList.remove("animate-pulse");

      if (this.buttonTarget) {
        this.buttonTarget.dataset.action = "click->draw#continueDraw";
      }

      if (this.hasAutoDrawButtonTarget) {
        if (document.getElementById("not-drawn") == null) {
          this.resetAutoDrawButton(this.buttonTarget.dataset.nextGroup, "btn-blue");
          this.autoDrawButtonTarget.dataset.action = "click->draw#continueDraw";

          // const buttonText = ("round_robin", "group").includes(this.formatValue)
          //   ? "Next Group →"
          //   : "Next Match →";
          // this.resetButton(buttonText, "btn-blue");
        } else {
          const stopText = this.autoDrawButtonTarget.dataset.stop;
          this.resetAutoDrawButton(
            `<i class='fa-solid fa-stop'></i> ${stopText}`,
            "btn-danger",
          );
          this.autoDrawButtonTarget.dataset.action = "click->draw#stopAutoDraw";
        }
      }
    }
  }

  handleError() {
    if (this.errorTarget) {
      this.errorTarget.classList.remove("hidden");
    }
    this.resetButton(this.buttonTarget.dataset.again, "btn-primary");
    this.resetAutoDrawButton(`Draw Again`, "btn-primary");
  }

  resetButton(text, btnClass) {
    this.buttonTarget.disabled = false;
    this.buttonTarget.classList.remove(
      "opacity-50",
      "btn-tertiary",
      "animate-pulse",
    );
    this.buttonTarget.classList.add(btnClass);
    this.buttonTarget.innerHTML = text;
  }

  resetAutoDrawButton(text, btnClass) {
    if (this.hasAutoDrawButtonTarget) {
      this.autoDrawButtonTarget.disabled = false;
      this.autoDrawButtonTarget.classList.remove(
        "opacity-50",
        "btn-tertiary",
        "btn-danger",
        "animate-pulse",
      );
      this.autoDrawButtonTarget.classList.add(btnClass);
      this.autoDrawButtonTarget.innerHTML = text;
    }
  }

  continueDraw() {
    window.location.reload();
  }
}
