import { Controller } from "@hotwired/stimulus"
import Cable from '../channels/cable'

export default class extends Controller {
    static targets = [
        "timer", "points", "games", "gameWrapper", "sets", "setWrapper", "tiebreak", "isServe",
        "scoreboard", "introduction"
    ]

    static values = {
        matchId: String,
        matchStatus: String,
        team1Id: String,
        team2Id: String,
        sportType: String
    }

    connect() {
        console.log("LED Screen controller connected")
        this.connectWebSocket()
    }

    connectWebSocket() {
        this.channel = Cable.subscribeTo("MatchChannel", {
            match_id: this.matchIdValue
        })

        this.channel.on("message", this.processEvent.bind(this))

        this.channel.on('connect', () => {
            console.log("Connected to MatchChannel")
            this.channel.perform('get_state')
        })

        // Add console.log for other events to match overlay_controller
        this.channel.on('disconnect', () => {
            console.log("Disconnected from MatchChannel")
        })

        this.channel.on('reject', () => {
            console.log("Connection rejected for MatchChannel")
        })

        this.channel.on('error', (error) => {
            console.log("Error in MatchChannel:", error)
        })
    }

    processEvent(data) {
        console.log("Event", data)

        switch (data.action) {
            case 'start':
            case 'start_match':
                this.handleStartMatch(data)
                break
            case 'update_score':
                this.handleScoreUpdate(data)
                break
            case 'match_state':
                this.handleMatchState(data)
                break
            case 'tiebreak_game':
                this.showTiebreakGame()
                break
            case 'switch_sides':
                this.handleSwitchSides(data)
                break
            case 'finished':
                this.handleMatchFinished()
                break
        }
    }

    handleStartMatch(data) {
        this.introductionTarget.classList.add('hidden')
        this.scoreboardTarget.classList.remove('hidden')

        if (data?.scores) {
            this.updateScores(data.scores)
        }
        if (data?.started_at) {
            this.startTimer(data.started_at)
        }
    }

    handleScoreUpdate(data) {
        console.log("Updating score")
        this.updateScores(data.scores)
        if (this.scoreboardTarget.classList.contains('hidden')) {
            this.introductionTarget.classList.add('hidden')
            this.scoreboardTarget.classList.remove('hidden')
        }
    }

    handleMatchState(data) {
        console.log("Match state")
        if (data.status === 'scheduled') {
            this.resetScores()
        } else {
            this.updateScores(data.scores)
        }
        this.startTimer(data.started_at)
        if (data.is_tiebreak_game === true) {
            this.showTiebreakGame()
        }
    }

    handleSwitchSides(data) {
        const hasTiebreakScore = data.scores.team1.sets?.some(set => 'tiebreak_score' in set) ||
            data.scores.team2.sets?.some(set => 'tiebreak_score' in set);

        if (data.scores.team1.points === '0' &&
            data.scores.team2.points === '0' &&
            data.scores.team1.current_game !== 2 &&
            !hasTiebreakScore) {
            // Handle switch sides if needed
        }
    }

    handleMatchFinished() {
        console.log("Match finished")
    }

    resetScores() {
        let zeroScores
        if (this.sportTypeValue === "tennis") {
            zeroScores = {
                team1: {
                    id: this.team1IdValue,
                    current_game: 0,
                    current_set: 1,
                    is_serve: false,
                    points: "-",
                    sets: [
                        {
                            games_won: 0,
                            is_super_tiebreak: false,
                            set_number: 1,
                            set_won: false
                        }
                    ]
                },
                team2: {
                    id: this.team2IdValue,
                    current_game: 0,
                    current_set: 1,
                    is_serve: false,
                    points: "-",
                    sets: [
                        {
                            games_won: 0,
                            is_super_tiebreak: false,
                            set_number: 1,
                            set_won: false
                        }
                    ]
                }
            }
        }
        else {
            zeroScores = {
                team1: {
                    id: this.team1IdValue,
                    current_game: 1,
                    is_serve: false,
                    points: "-",
                    games: [
                        {
                            game_number: 1,
                            points: 0,
                            game_won: false
                        }
                    ]
                },
                team2: {
                    id: this.team2IdValue,
                    current_game: 1,
                    is_serve: false,
                    points: "-",
                    games: [
                        {
                            game_number: 1,
                            points: 0,
                            game_won: false
                        }
                    ]
                }
            }
        }
        this.updateScores(zeroScores)
    }

    updateScores(scores) {
        Object.entries(scores).forEach(([teamKey, scoreDetails]) => {
            this.updateScore("points", scoreDetails.id, scoreDetails.points)
            this.updateScore("games", scoreDetails.id, scoreDetails.current_game)
            if (this.sportTypeValue === "tennis") {
                this.updateSets(scoreDetails.id, scoreDetails.sets)
            } else {
                this.updateGames(scoreDetails.id, scoreDetails.games)
            }
            this.updateIsServe(scoreDetails.id, scoreDetails.is_serve)
        })
    }

    updateScore(scoreType, teamId, score) {
        console.log('Updating score', scoreType, teamId, score)
        const scoreTargets = this[scoreType + "Targets"]
        const target = Array.from(scoreTargets).find(target => target.dataset.teamId === teamId)
        if (target) {
            target.textContent = score
        }
    }

    updateSets(teamId, sets) {
        try {
            const setWrapperTarget = this.setWrapperTargets.find(target => target.dataset.teamId === teamId)
            if (!setWrapperTarget) return

            while (setWrapperTarget.children.length < sets.length) {
                const newSetDiv = this.createSetDiv(teamId, setWrapperTarget.children.length + 1)
                setWrapperTarget.appendChild(newSetDiv)
            }

            sets.forEach((set, index) => {
                const setDiv = setWrapperTarget.children[index]
                const scoreElement = setDiv.querySelector(`[data-ledscreen-target="sets"][data-team-id="${teamId}"][data-set-number="${set.set_number}"]`)
                const tiebreakElement = setDiv.querySelector(`[data-ledscreen-target="tiebreak"][data-team-id="${teamId}"][data-set-number="${set.set_number}"]`)

                if (scoreElement) {
                    scoreElement.textContent = set.games_won
                    scoreElement.classList.toggle("set-won", set.set_won)
                }

                if (tiebreakElement && set.tiebreak_score) {
                    tiebreakElement.textContent = set.tiebreak_score
                }

                setDiv.classList.toggle("text-black", index === sets.length - 1)
                setDiv.classList.toggle("bg-gray-100", index !== sets.length - 1)
            })
        } catch (error) {
            console.error("Error updating sets:", error)
        }
    }

    updateGames(teamId, games) {
        try {
            const gameWrapperTarget = this.gameWrapperTargets.find(target => target.dataset.teamId === teamId)
            if (!gameWrapperTarget) return

            while (gameWrapperTarget.children.length < games.length) {
                const newGameDiv = this.createGameDiv(teamId, gameWrapperTarget.children.length + 1)
                gameWrapperTarget.appendChild(newGameDiv)
            }

            games.forEach((game, index) => {
                const gameDiv = gameWrapperTarget.children[index]
                const scoreElement = gameDiv.querySelector(`[data-ledscreen-target="games"][data-team-id="${teamId}"][data-game-number="${game.game_number}"]`)
                const tiebreakElement = gameDiv.querySelector(`[data-ledscreen-target="tiebreak"][data-team-id="${teamId}"][data-game-number="${game.game_number}"]`)

                if (scoreElement) {
                    scoreElement.textContent = game.points
                    scoreElement.classList.toggle("game-won", game.game_won)
                }

                gameDiv.classList.toggle("text-black", index === games.length - 1)
                gameDiv.classList.toggle("bg-gray-100", index !== games.length - 1)
            })
        } catch (error) {
            console.error("Error updating games:", error)
        }
    }

    createSetDiv(teamId, setNumber) {
        const setDiv = document.createElement('div')
        setDiv.classList.add('relative', 'w-8', 'flex', 'items-center', 'justify-center', 'text-xl', 'font-bold', 'text-carbon-700')

        const scoreElement = document.createElement('div')
        scoreElement.setAttribute('data-ledscreen-target', 'sets')
        scoreElement.setAttribute('data-team-id', teamId)
        scoreElement.setAttribute('data-set-number', setNumber)
        scoreElement.textContent = '0'

        const tiebreakElement = document.createElement('span')
        tiebreakElement.setAttribute('data-ledscreen-target', 'tiebreak')
        tiebreakElement.setAttribute('data-team-id', teamId)
        tiebreakElement.setAttribute('data-set-number', setNumber)
        tiebreakElement.classList.add('absolute', '-right-0', '-top-2', 'text-[16px]', 'font-medium', 'text-carbon-400')

        setDiv.appendChild(scoreElement)
        setDiv.appendChild(tiebreakElement)

        return setDiv
    }

    createGameDiv(teamId, gameNumber) {
        const gameDiv = document.createElement('div')
        gameDiv.classList.add('relative', 'w-14', 'h-[56px]', 'flex', 'items-center', 'justify-center', 'text-5xl', 'font-bold', 'text-gray-100')

        const scoreElement = document.createElement('div')
        scoreElement.setAttribute('data-ledscreen-target', 'games')
        scoreElement.setAttribute('data-team-id', teamId)
        scoreElement.setAttribute('data-game-number', gameNumber)
        scoreElement.textContent = '0'

        const tiebreakElement = document.createElement('span')
        tiebreakElement.setAttribute('data-ledscreen-target', 'tiebreak')
        tiebreakElement.setAttribute('data-team-id', teamId)
        tiebreakElement.setAttribute('data-game-number', gameNumber)
        tiebreakElement.classList.add('absolute', '-right-1', '-top-2', 'text-2xl', 'font-medium', 'text-gray-400')

        gameDiv.appendChild(scoreElement)
        gameDiv.appendChild(tiebreakElement)

        return gameDiv
    }

    updateIsServe(teamId, isServe) {
        const isServeTarget = this.isServeTargets.find(target => target.dataset.teamId === teamId)
        if (isServeTarget) {
            isServeTarget.classList.toggle("hidden", !isServe)
        }
    }

    showTiebreakGame() {
        console.log("Tiebreak game started")
        // Implementation for visually representing a tiebreak game
    }

    startTimer(startedAt = new Date()) {
        this.startTime = new Date(startedAt).getTime()
        this.stopTimer() // Clear any existing timer
        this.timer = setInterval(() => {
            this.updateTimer()
        }, 1000)
    }

    stopTimer() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    updateTimer() {
        const now = new Date().getTime()
        const timeElapsed = now - this.startTime
        const hours = Math.floor((timeElapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((timeElapsed % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((timeElapsed % (1000 * 60)) / 1000)

        const formattedTime = [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0')
        ].join(':')

        if (this.hasTimerTarget) {
            this.timerTarget.textContent = formattedTime
        }
    }

    disconnect() {
        this.stopTimer()
        if (this.channel) {
            this.channel.unsubscribe()
        }
    }
} 