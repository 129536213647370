import { Controller } from "@hotwired/stimulus";
import intlTelInput from 'intl-tel-input';
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
    static targets = ["phoneInput", "phoneHiddenInput", "phoneLoginForm"]

    connect() {
        // useIntersection(this)
        this.phoneInput(); // intlTelInput
        this.setPhoneNumber();
    }
    
    disconnect() {
        console.log('Disconnecting PhoneInputController');
        this.iti.destroy();
    }

    phoneInput() {
        const input = this.phoneInputTarget;
        const userCountry = this.element.dataset.userCountry || "VN";
        this.iti = intlTelInput(input, {
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
            initialCountry: "vn",
            preferredCountries: ["vn", "my"],
            separateDialCode: true,
            autoPlaceholder: "aggressive"
        });

        if(userCountry == "MY" && !input.value) {
            this.iti.setNumber("+60");
        }
    }
    
    change() {
        let phoneNumber = this.iti.getNumber(window.intlTelInputUtils.numberFormat.E164);

        this.phoneHiddenInputTarget.value = phoneNumber;
    }

    setPhoneNumber() {
        if (this.phoneHiddenInputTarget.value) {
            this.iti.setNumber(this.phoneHiddenInputTarget.value);
        }
    }
}